import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    // Determine if the link should be active based on the current URL
    const isActive = (path) => location.pathname === path;

    return (
        <header>
            <div className="logo">
                <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h1>Cristian TIRCHE</h1>
                </a>
            </div>
            <nav>
                <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
                    <li>
                        <Link to="/home" className={(isActive('') || isActive('/') || isActive('/home')) ? 'active' : ''}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/ressources" className={isActive('/ressources') ? 'active' : ''}>
                            Ressources
                        </Link>
                    </li>
                    <li>
                        <Link to="/ppp-2024/cv-2024" className={isActive('/ppp-2024/cv-2024') ? 'active' : ''}>
                            Resume
                        </Link>
                    </li>
                </ul>
                <button 
                    className="menu-toggle" 
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    ☰
                </button>
            </nav>
        </header>
    );
};

export default Header;
